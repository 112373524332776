import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setOrdered } from "store/checkout/order/orderSlice";
import {
    getTableLaterOrders
  } from "apiHelpers/api/ApiHelpers";
import { useCustomHistory } from "hooks";

const usePayLaterOrders = (brandInfo) => {
  const { DomainType } = useSelector((state) => state.AppReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  const router = useCustomHistory();
  const [error, setError] = useState(null);
  const brandId = brandInfo?.brand?._id;

  const queryParams = new URLSearchParams(location.search);
  const tableNumber = queryParams.get('table-number');
  const storeId = queryParams.get('store-id');

  useEffect(() => {
    if (location.pathname.includes('/dine-in') && brandId && tableNumber) {
      const fetchPayLaterOrders = async () => {
        try {
          const response = getTableLaterOrders({ brandId, tableNumber, storeId });
          const result = await response;
          const fetchedOrders = result?.data?.fetchedOrder;

          if (fetchedOrders) {
            dispatch(setOrdered({
              ordersTotalPrice: parseFloat((fetchedOrders.reduce((total, order) => total + (order.totalOrderPrice || 0), 0)).toFixed(2)),
              ordersTotalTax: parseFloat((fetchedOrders.reduce((total, order) => {
                const itemsTotalPrice = order?.itemsTotalPrice || 0;
                const taxRate = order?.taxRate || 0;
                const taxAmount = (itemsTotalPrice * taxRate) / 100;
                return total + (isNaN(taxAmount) ? 0 : taxAmount);
              }, 0)).toFixed(2)),
              totalTipAmount: parseFloat((fetchedOrders.reduce((total, order) => total + order.tipAmount , 0)).toFixed(2)),
              allOrders: fetchedOrders.map((fo) => ({
                selectedPaymentMethod: fo.selectedPaymentMethod,
                ...(fo.selectedTip && {
                  selectedTip:fo.selectedTip
                } ),
                paymentType: fo.paymentType,
                discountApplied: fo.discountApplied,
                itemsTotalPrice: fo.itemsTotalPrice,
                orderCurrency: fo.orderCurrency,
                orderedMenu: fo.orderedMenu,
                orderNumber: fo.orderNumber,
                orderPlacedTime: fo.orderPlacedTime,
                totalOrderPrice: fo.totalOrderPrice,
                taxRate: fo.taxRate,
                _id: fo._id,
              })),
            }));
            if (fetchedOrders.length > 0) {
              PayLaterTab();
            }
          } else {
            dispatch(setOrdered({}));
          }
        } catch (err) {
          setError(err);
        }
      };

      fetchPayLaterOrders();
    }
  }, [brandId, tableNumber, storeId]);

  const PayLaterTab = () => {
    if (brandInfo?.setting?.temporaryDomainName) {
      router(
        `/dine-in/paylater`,
        `/${brandInfo.setting.temporaryDomainName}/dine-in/paylater`,
        DomainType,
        null,
        `?${queryParams}`
      );
    }
  };

  return { error };
};

export default usePayLaterOrders;
