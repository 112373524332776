import { apiSlice } from "../../../apiHelpers/ApiSlice";
import * as URL from "../../../apiHelpers/api/ApiUrls";
import { TRACKING_EVENTS } from "utils";
import {
  OrderAction,
  CartAction,
  DiscountAction,
  Loader,
  StoreAction,
  ErrorAction,
  LoyaltyAction,
  PaymentMethodAction,
  DeliveryAddressAction,
  TipMethodsAction
} from "../../actions";
import { errorHandler } from "apiHelpers/api/errorHandler";

const orderSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    placeMyOrder: builder.mutation({
      query: (body) => ({
        url: URL.PLACE_ORDER,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.togggleCheckoutLoader(true));

        try {
          const { data } = await queryFulfilled;
          if (data?.success === 1) {
            let cart = getState()?.cart;
            if (cart?.cartTotal) {
              TRACKING_EVENTS.purchase(cart.cartTotal);
            }
            // clear context data

            dispatch(CartAction.clearCart());
            dispatch(OrderAction.placeOrderSuccess(data.data));
            dispatch(DiscountAction.removeDiscountCode());
            dispatch(StoreAction.setSelectedPaymentMethod(null));
            dispatch(PaymentMethodAction.setSelectedPaymentType(null));
            dispatch(LoyaltyAction.removeLoyalty());
            dispatch(DeliveryAddressAction.resetReducer());
            dispatch(TipMethodsAction.setSelectedTip(''));
            dispatch(OrderAction.resetOrdered());
            setTimeout(() => {
              dispatch(ErrorAction.resetErrorReducer());
            }, 2000);
          } else {
            dispatch(
              OrderAction.placeOrderFailure(errorHandler(data?.message))
            );
          }
        } catch (err) {
          dispatch(OrderAction.placeOrderFailure(errorHandler(err?.message)));
        } finally {
          setTimeout(() => {
            dispatch(CartAction.togglePullAddress(true));
            dispatch(Loader.togggleCheckoutLoader(false));
          }, 1000);
        }
      },
    }),
    getHostedPage: builder.mutation({
      query: (body) => ({
        url: URL.GET_HOSTED_PAYMENT_PAGE,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.togggleCheckoutLoader(true));

        try {
          const { data } = await queryFulfilled;
          if (
            data?.success === 1 &&
            data?.data?.transactionInfo &&
            data?.data?.paymentURL
          ) {
            dispatch(
              OrderAction.getPaymentPageSuccess(data.data.transactionInfo)
            );
            // window.location.href = data.data.paymentURL;
            window.location.replace(data.data.paymentURL);
          } else {
            dispatch(
              ErrorAction.setError({
                error: errorHandler(data?.message),
                modal: true,
              })
            );
            dispatch(Loader.togggleCheckoutLoader(false));
          }
        } catch (err) {
          dispatch(
            ErrorAction.setError({
              error: errorHandler(err?.message),
              modal: true,
            })
          );
          dispatch(Loader.togggleCheckoutLoader(false));
        }
        setTimeout(() => {
          dispatch(Loader.togggleCheckoutLoader(false));
        }, 5000);
      },
    }),
    verifyPayment: builder.mutation({
      query: (body) => ({
        url: URL.VERIFY_PAYMENT,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        dispatch(Loader.togggleCheckoutLoader(true));

        try {
          const { data } = await queryFulfilled;
        } catch (err) {}
        setTimeout(() => {
          dispatch(Loader.togggleCheckoutLoader(false));
        }, 4000);
      },
    }),
  }),
});

export default orderSlice;
export const {
  usePlaceMyOrderMutation,
  useGetHostedPageMutation,
  useVerifyPaymentMutation,
} = orderSlice;
