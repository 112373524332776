import { get, post } from "./ApiKit";
import * as url from "./ApiUrls";

export const getBrandMappedDeliveryAreas = (data) =>
  post(url.GET_MAP_DELIVERY_AREAS, data);

export const getBrandPickupStores = (data) =>
  post(url.GET_BRAND_PICKUP_STORES, data);

export const getBrandsById = (data) => post(url.GET_BRAND_SETTINGS_BYID , data);
export const getUserBrandsBySlug = (data) => get(`/user/${data?.slug ?? ''}/${url.GET_BRANDS_FROM_SLUG}`);

export const getTableLaterOrders = (data) => post(`/order/getTableLaterOrders` , data);

//STORE API
export const getStoresByAreaId = (data) =>
  post(url.GET_STORES_BY_AREA_ID, data);
export const getStoresByMappedAreaId = (data) =>
  post(url.GET_STORES_BY_MAP_AREA, data);

export const getDeliveryStoreMenu = (data) =>
  post(url.GET_DELIVERY_STORE_MENU, data);
export const getStoreMenuForPickup = (data) =>
  post(url.GET_STORE_MENU_FOR_PICKUP, data);

export const getMyOrders = (data) => get(url.GET_MY_ORDERS);
export const getOrderStatus = (data) =>
  get(url.GET_ORDER_INFO_POPULATED + data);

//DISCOUNT API
export const getAllDiscounts = (data) => post(url.GET_DISCOUNTS, data);

export const validateDiscount = (data) => post(url.VALIDATE_DISCOUNT, data);
//payments
export const getPaymentHostedPage = (data) =>
  post(url.GET_HOSTED_PAYMENT_PAGE, data);
export const verifyPayment = (data) => post(url.VERIFY_PAYMENT, data);
export const createStripePaymentIntent = (data) =>
  post(url.CREATE_STRIPE_PAYMENT_INTENT, data);
export const verifyStripePaymentIntent = (data) =>
  post(url.VERIFY_STRIPE_PAYMENT_INTENT, data);

//LOYALTY POLICY
export const getUserWallet = (data) => post(url.GET_USER_WALLET, data);

//STRIPE pl links
export const createStripePaymentLink = (data) =>
  post(url.CREATE_STRIPE_PL_INTENT, data);
export const verifyStripePaymentLink = (data) =>
  post(url.VERIFY_STRIPE_PL_INTENT, data);

//feedback
export const submitUserFeedback = (data) =>
  post(url.SUBMIT_USER_FEEDBACK, data);

export const getOrderDetail = (data) => post(url.GET_ORDER_DETAIL_BY_ID + data);
export const checkIfLAtLngInPolygon = (data) =>
  post(url.CHECK_POLYGON_CONTAIN, data);

export const getItemDetailWithOutStoreId = (data) =>
  post(url.GET_DIRECT_ITEM_DETAIL, data);
export const getItemDetailWithStoreId = (data) =>
  post(url.GET_MENU_ITEM_DETAIL, data);

export const getStoresBylatLng = (data) =>
  post(url.GET_STORES_BY_LAT_LNG, data);

export const getBrandShippingMethod = (data) =>
  post(url.GET_BRAND_ACTIVE_SHIPPING_METHOD, data);

export const checkStoreAvailability = (data) =>
  post(url.CHECK_STORE_AVAILABILITY, data);
export const updateUserProfile = (data) => post(url.UPDATE_USER_PROFILE, data);
