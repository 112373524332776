import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Spinner, NotFound, ErrorComponent } from "components";
import DiscountHomeContainer from "components/discounts/DiscountHomeContainer";
import LoyaltyPolicyWidget from "components/brand-loyalty/LoyaltyPolicyWidget";

import * as commonHooks from "hooks";

import C from "Constants";
import {
  getUserBrandsBySlug,
  getBrandsById,
  getAllDiscounts,
} from "apiHelpers/api/ApiHelpers";
import { t } from "i18next";
import { WhatsappIcon } from "icons";

const PhoneIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 473.806 473.806"
      fill="currentColor"
      className={`w-6 h-6 ${props?.className}`}
      {...props}
    >
      <g>
        <g>
          <path d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4 c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8 c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6 c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5 c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26 c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9 c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806 C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20 c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6 c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1 c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3 c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5 c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8 c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9 l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1 C420.456,377.706,420.456,388.206,410.256,398.806z" />
          <path d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2 c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11 S248.656,111.506,256.056,112.706z" />
          <path d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11 c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2 c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z" />
        </g>
      </g>
    </svg>
  );
};

const BusinessCardContainer = () => {
  const params = useParams();
  commonHooks.usePlatform();
  const { currentLang } = commonHooks.useLang();
  const [brands, setBrands] = useState([]);
  const [card, setCard] = useState({
    title:'',
    phoneNumber:''
  });
  const [isLoading , setIsLoading ] = useState(false);
  const [error , setError] = useState('');
  

  const { notFound, brandMenu, discounts, brandMenuError } = useSelector(
    (state) => state?.brand
  );
  const { loyaltyPolicy, brandInfo } = useSelector(
    (state) => state?.brandStorageReducer
  );

  useEffect(() => {
    const getBrandsFromSlug = async () => {
      try {
        setIsLoading(true);
        const response = getUserBrandsBySlug({
          slug: params?.slug,
        });
        const result = await response;
        setCard({ phoneNumber:result.data.phoneNumber ,  title: result.data.title});
        const allBrands = await Promise.all(
          result.data?.permittedBrands.map(async (pB) => {
            const [brandData, discounts] = await Promise.all([
              getBrandsById({ brandId: pB }),
              getAllDiscounts({ brandId: pB }),
            ]);

            return {
              ...brandData,
              ...discounts,
            };
          })
        );
        const extractedBrands = allBrands.map((eB) => ({
          ...eB.data,
          discounts: eB.discount,
        }));
        setBrands(extractedBrands);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        // console.error("error fetching stores", error);
        setIsLoading(false);
      }
    };
    getBrandsFromSlug();

    return () => {};
  }, ['']);

  const goToLogin = () => {};

  const handleRedirect = (name) => {
    const url = `/${name}`;
    window.location.href = url;
  };

  const allSameWhatsApp = brands.every(
    (store, index, array) => store.mobileNumber === brands[0].mobileNumber
  );

  if (isLoading) return <Spinner />;
  else if (!isLoading && notFound) return <NotFound message={notFound} />;
  return (
    <div className="store-page store-page-popup">
      <div
        className={`bg-cover w-full bg-center pb-56p
        }`}
        style={{
          backgroundImage: `url('images/businesscard-cover.jpg')`,
        }}
      ></div>
      <div
        className={`${
          C.BRAND === "brand" ? "pt-5" : "py-5"
        } px-4 bg-white flex justify-between flex-col`}
      >
        <div className="flex justify-between">
          <h1 className="font-nunito text-1.5 font-extrabold text-black mb-1">
          {t(card?.title ?? 'Card Title')}
          </h1>
        </div>

        <div className="p-3 bg-grey-green-off rounded text-sm leading-normal text-black mt-4">
          {t("Get Your Food From Here!")}
        </div>
      </div>
      <div>{error && <ErrorComponent error={error} />}</div>
      <DiscountHomeContainer {...{ discounts }} selectedStore={null} />
      {loyaltyPolicy?.isLoyaltyActive && (
        <LoyaltyPolicyWidget {...{ loyaltyPolicy, goToLogin }} />
      )}
      <div>{error && <ErrorComponent error={error} />}</div>
      <div className="flex flex-wrap px-2 mt-2 mb-4">
        {brands.map((st, key) => (
          <div className="flex p-2 w-1/2" key={key + 1}>
            <div className="flex w-full flex-col  border border-gray-200 text-black rounded-md overflow-hidden">
              <div
                className="bg-center bg-cover rounded-t-md"
                style={{
                  backgroundImage: `url(${st?.setting?.brandImageHomeBG})`,
                  opacity: 1,
                  height: "9.75rem",
                }}
              ></div>
              <div className="h-1/3 p-2 font-bold">
                <div>{st.brand.brandName}</div>
                {st?.discounts?.length > 0 && st?.discounts?.some(discount => discount.discountApplication !== 'hidden') && <div className="flex flex-col w-full border-2 border-brand-base-primary border-dashed p-1 rounded-md">
                  <span>{t("Discounts !")}</span>
                  <div className="flex flex-wrap">
                    {st.discounts.filter(discount => discount.discountApplication !== 'hidden').map((d , key) => <div key={key} className="mt-2 mb-2 rounded-full py-1 px-2 text-brand-text-primary bg-brand-base-primary">
                      {t(d.discountName)}
                    </div>)}
                  </div>
                </div>}
              </div>
              <div className="p-2 mt-auto">
                <button
                  type="button"
                  onClick={() => handleRedirect(st.setting.temporaryDomainName)}
                  style={{ marginTop: "auto" }}
                  className="border-2 text-brand-base-primary border-brand-base-primary hover:bg-brand-base-primary hover:text-brand-text-primary w-full py-3 rounded px-6 text-brand-text-primary text-16x font-semibold"
                >
                  <span className="flex items-center justify-center text-center">
                    <div className="flex relative w-full items-center pointer">
                      <span className="my-0 mx-auto">{t("Order Now!")}</span>
                    </div>
                  </span>
                </button>
              </div>
              {!allSameWhatsApp && (
                <div className="flex w-full bg-gray-100 flex-col">
                  {(st.whatsAppContact || st.mobileNumber) && (
                    <a
                      className="p-2 flex w-full svg-icon text-center align-center justify-center hover:text-brand-text-primary hover:bg-brand-base-primary"
                      href={`https://wa.me/${
                        st.whatsAppContact || st.mobileNumber || ""
                      }`}
                    >
                      <WhatsappIcon />
                      <div className="ml-2 flex text-center align-middle justify-center self-center font-bold text-14x">
                        
                        {t("Whatsapp us!")}
                      </div>
                    </a>
                  )}

                  {st.mobileNumber && (
                    <a
                      href={`tel:${st?.mobileNumber}`}
                      className="p-2 flex w-full svg-icon text-center align-center justify-center hover:text-brand-text-primary hover:bg-brand-base-primary"
                    >

                      <PhoneIcon />

                      <div className="ml-2 font-bold text-16x flex text-center align-middle justify-center self-center">
                        {st?.mobileNumber}
                      </div>
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex w-full mb-4 px-2 mt-auto">
        {card?.phoneNumber && (
            <div className="flex w-1/2 p-2">
              <a
                className="p-2 flex w-full border border-gray-200 rounded-md svg-icon text-center align-center justify-center hover:text-brand-text-primary hover:bg-brand-base-primary"
                href={`https://wa.me/${
                  card?.phoneNumber || ""
                }`}
              >
                <WhatsappIcon className="w-14 h-14" />
                <div className="ml-2 flex text-center align-middle justify-center self-center font-bold text-14x">
                {t("Whatsapp us!")}
                </div>
              </a>
            </div>
          )}

        {card?.phoneNumber && (
          <div className="flex w-1/2 p-2">
            <a
              href={`tel:${card.phoneNumber}`}
              className="p-2 flex w-full items-center border border-gray-200 rounded-md svg-icon text-center align-center justify-center hover:text-brand-text-primary hover:bg-brand-base-primary"
            >

              <PhoneIcon />

              <div className="ml-2 font-bold text-16x flex text-center align-middle justify-center self-center">
                {card.phoneNumber}
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessCardContainer;
