import React from "react";

function Whatsapp(props) {
  
  return (
    <svg
      viewBox="0 0 28 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className={`${props.className ?? 'w-8 h-8'}`}
      style={props.style ?? {}}
    >
      <g clipPath="url(#whatsapp_svg__graphic-whatsapp-logo)">
        <path
          d="M23.92 4.069A13.885 13.885 0 0014.06 0C6.375 0 .121 6.224.119 13.874a13.787 13.787 0 001.86 6.936L.001 28l7.39-1.93a13.98 13.98 0 006.662 1.69h.006c7.683 0 13.937-6.225 13.94-13.875A13.76 13.76 0 0023.92 4.07zm-9.86 21.348h-.004a11.612 11.612 0 01-5.898-1.608l-.423-.25-4.386 1.145 1.171-4.256-.276-.436a11.465 11.465 0 01-1.771-6.137c.003-6.359 5.2-11.532 11.591-11.532a11.537 11.537 0 018.192 3.382 11.436 11.436 0 013.39 8.16c-.002 6.358-5.2 11.531-11.586 11.531z"
          fill="#E0E0E0"
        ></path>
        <path
          d="M.64 27.202l1.888-6.86a13.17 13.17 0 01-1.776-6.62C.755 6.422 6.723.482 14.056.482a13.244 13.244 0 019.41 3.883 13.128 13.128 0 013.893 9.367c-.003 7.3-5.971 13.24-13.303 13.24h-.006a13.34 13.34 0 01-6.357-1.61l-7.052 1.84z"
          fill="url(#whatsapp_svg__graphic-whatsapp-logo-1)"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.614 7.984c-.258-.57-.53-.582-.775-.592-.2-.009-.43-.008-.66-.008-.23 0-.603.086-.918.429-.316.343-1.205 1.172-1.205 2.858 0 1.687 1.234 3.317 1.406 3.546.172.228 2.382 3.8 5.881 5.174 2.909 1.142 3.5.915 4.132.858.632-.057 2.037-.83 2.324-1.63.287-.8.287-1.486.201-1.63-.086-.142-.315-.228-.66-.4-.344-.17-2.037-1-2.353-1.115-.315-.114-.545-.171-.774.172-.23.343-.89 1.115-1.09 1.344-.201.229-.402.257-.747.086-.344-.172-1.453-.534-2.768-1.702-1.024-.908-1.715-2.03-1.916-2.373-.2-.343-.021-.53.151-.7.155-.154.345-.4.517-.6.172-.2.229-.343.344-.572.115-.229.057-.429-.029-.6-.086-.172-.755-1.867-1.061-2.545z"
          fill="#fff"
        ></path>
        <path
          d="M23.808 4.025A13.725 13.725 0 0014.06.003C6.464.003.282 6.155.279 13.717c0 2.418.634 4.777 1.84 6.857L.162 27.681l7.306-1.907a13.817 13.817 0 006.585 1.67h.005c7.595 0 13.778-6.154 13.781-13.716a13.602 13.602 0 00-4.032-9.703zM14.06 25.127h-.005a11.479 11.479 0 01-5.83-1.589l-.418-.247-4.335 1.132 1.157-4.207-.272-.431a11.332 11.332 0 01-1.751-6.067C2.608 7.433 7.746 2.32 14.064 2.32a11.405 11.405 0 018.098 3.343 11.305 11.305 0 013.351 8.066c-.003 6.285-5.14 11.4-11.453 11.4z"
          fill="#fff"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="whatsapp_svg__graphic-whatsapp-logo-1"
          x1="14"
          y1="27.202"
          x2="14"
          y2=".482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20B038"></stop>
          <stop offset="1" stopColor="#60D66A"></stop>
        </linearGradient>
        <clipPath id="whatsapp_svg__graphic-whatsapp-logo">
          <path fill="#fff" d="M0 0h28v28H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Whatsapp;
