import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NotPersistAction } from "store/actions";
import { createAction } from "@reduxjs/toolkit";

import Modal from "react-modal";
import { logErrorOnSentry } from "utils/Utils";
const CustomModal = (props) => {
  const dispatch = useDispatch();
  const [scrollY, setScrollY] = useState(0);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  const setModal = () => {
    try {
      if (props?.setOpen) {
        props.setOpen(false); // Close modal with local state if used
      } else {
        dispatch(NotPersistAction.setModalClose()); // Use Redux if no local state control
      }
  
      if (props?.reduxAction?.type) {
        const action = createAction(props.reduxAction.type);
        dispatch(action(props.reduxAction.payload));
      }
    } catch (error) {
      logErrorOnSentry(`Error in closing modal: ${JSON.stringify(error)}`, "error");
    }
  };
  function closeModal() {}

  // const parentDivTop1 = document.body.getBoundingClientRect()?.top;
  useEffect(() => {
    // const originalStyle = {
    //   position: document.body.style.position,
    //   top: document.body.style.top,
    //   overflow: document.body.style.overflow,
    //   width: document.body.style.width,
    // };
  
    // if (props?.open) {
    //   document.body.style.position = "fixed";
    //   document.body.style.top = `${parentDivTop1}px`;
    //   document.body.style.overflow = "hidden";
    //   document.body.style.width = "100%";
    // }
  
    // return () => {
    //   Object.assign(document.body.style, originalStyle);
    //   window.scrollTo(0, -parentDivTop1);
    // };
    if (props?.open) {
      const savedScrollY = window.scrollY; // Capture scroll position
      setScrollY(savedScrollY); // Save it in state
      document.body.style.position = "fixed";
      document.body.style.top = `-${savedScrollY}px`; // Use negative scroll value
      document.body.style.overflow = "hidden";
      document.body.style.width = "100%";
    } else {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.overflow = "";
      document.body.style.width = "";
      window.scrollTo(0, scrollY); // Restore scroll position
    }
  }, [props?.open]);
  
  return (
    <Modal
      isOpen={props.open}
      onAfterOpen={afterOpenModal}
      onRequestClose={setModal}
      className={props.bodyclasses}
      overlayClassName={props.overlayClasses}
      shouldCloseOnEsc={props.shouldCloseOnEsc ? false : true}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick ? false : true}
      ariaHideApp={false}
      parentSelector={() => document.querySelector(".store-page-popup") || document.body}
    >
      {props.children}
    </Modal>
  );
};
export default CustomModal;
