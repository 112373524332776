import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { t } from "i18next";

import ButtonSpinner from "components/ButtonSpinner";
import { CartAction } from "store/actions";
import { useCustomHistory } from "hooks";
import { useMapContext } from "contexts/googleMap";
import useCheckStoreAvailability from "service-hooks/useCheckStoreAvailability";
import { calculateCartGrossAmount } from "utils/Utils";
import C from "Constants";

function PlaceOrderButton({ placeOrder, loading }) {
  const dispatch = useDispatch();
  const { reset } = useMapContext();
  const { checkStoreAvailability } = useCheckStoreAvailability();

  //global state
  const { cart } = useSelector((state) => state?.cart);
  const { ordered } = useSelector((state) => state?.order);
  const {allOrders} = ordered;
  const { discountInfo } = useSelector((state) => state?.Discounts);
  const { selectedStore } = useSelector((state) => state?.store);
  const { orderTiming } = useSelector((state) => state?.orderTimingReducer);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { orderType } = useSelector((state) => state?.AppReducer);
  const { selectedPaymentType } = useSelector(
    (state) => state.PaymentMethodReducer
  );

  useEffect(() => {
    const { itemsGrossAmount, itemsNetPrice, orderGrossAmount } =
      calculateCartGrossAmount(cart, discountInfo , 0 , ordered);
    dispatch(
      CartAction.updateCartPrice({
        originalPrice: itemsNetPrice,
        amountAfterDiscount: itemsGrossAmount,
        itemsGrossAmount,
        orderGrossAmount,
      })
    );
  }, [cart, discountInfo, dispatch, selectedStore]);

  const placeMyOrder = async () => {
    if (orderTiming?.TimingType === C.ORDER_TIMING_TYPE.PREORDER) {
      placeOrder();
      reset();
    } else {
      const orderDetails = {
        brandId: brandInfo?.brand?._id,
        storeId: selectedStore?._id,
        serviceType: orderType?.serviceType,
      };
      const isOpenStore = await checkStoreAvailability(orderDetails);
      if (!isOpenStore) return;
      placeOrder();
      reset();
    }
  };

  return (
    <React.Fragment>
      {((allOrders?.length > 0 && selectedPaymentType !== C.PAY_LATER ) || cart?.length > 0)  && (
        <div className="block w-full">
          <button
            onClick={placeMyOrder}
            type="submit"
            disabled={loading}
            className="mt-6 bg-brand-base-primary text-brand-text-primary w-full py-3 rounded px-6 text-17x font-medium cursor-pointer disabled-button"
          >
            {loading && (
              <ButtonSpinner
                height="30px"
                width="30px"
                color="text-white mx-1 inline-block"
              />
            )}
            <span className="font-bold text-17x">
              {loading ? t("Payment processing…") : t("Place order")}
            </span>
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

export default PlaceOrderButton;

function MinOrder({ minOrder, cartTotal, storePageUrl }) {
  const router = useCustomHistory();
  const params = useParams();
  const { DomainType } = useSelector((state) => state.AppReducer);
  const getValue = () => {
    const orderValue = minOrder - cartTotal;
    return orderValue >= 0 ? orderValue?.toFixed(2) : 0;
  };
  const gotoStorePage = () =>
    router(storePageUrl, `/${params?.domainName}${storePageUrl}`, DomainType);

  return (
    <button
      onClick={gotoStorePage}
      type="button"
      className="bg-brand-base-primary w-full py-3 rounded px-6 text-brand-text-primary text-16x font-semibold"
    >
      <span className="flex items-center justify-center text-center">
        <div className="flex relative w-full items-center pointer">
          <span className="my-0 mx-auto">
            {t("Add")}
            {getValue()}
            {t("AED")}
            {t("more")}
          </span>
        </div>
      </span>
    </button>
  );
}
export { MinOrder };
