import React from "react";
import { useSelector } from "react-redux";
import { useLang } from "hooks";
import { t } from "i18next";
import { UTILS } from "utils";

function OrderedBasketItemList() {
  const { currentLang } = useLang();
  const { ordered } = useSelector((state) => state.order);
  const {allOrders} = ordered;

  return (
    <div className="basket-contents__items px-4 py-6 border-b-4 border-black-100">
      <h4 className="text-outer-space text-17x font-semibold mb-4">
        {t("Previous Ordered Items")}
      </h4>
      <ul className="basket-contents__item-list">
        <li className="flex justify-between items-start flex-row flex-100 mb-4 text-16x font-bold border-b-2">
          <span>
            Item
          </span>
          <span className="mx-2 font-bold">Qty</span>
        </li>
        {allOrders?.map( al => al.orderedMenu?.map((item, index) => {
          return (
            <li
              className="basket-item flex justify-between items-start flex-row flex-100 mb-4 text-16x"
              key={index}
            >
              <span className="item flex flex-auto justify-start flex-wrap">
                <div className="flex-100">
                  <div className="text-outer-space block leading-22x">
                    {item?.baseLanguage?.title}
                  </div>
                  <div className="text-outer-space leading-22x">
                    {currentLang === "AR"
                      ? UTILS.toFixedNumber(item?.price) + " " + t("AED")
                      : t("AED") + " " + UTILS.toFixedNumber(item?.price)}
                  </div>
                  {item?.sections?.map((section, index) => {
                    return (
                      <div className=" first-word-capital" key={index}>
                        <span className="bold-font-size text-15x text-gray-500">
                          {section?.baseLanguage?.sectionName}
                        </span>

                        {section?.options?.map((option, index) => {
                          return (
                            <div key={index}>
                              <span className="text-13x ml-2">
                                <span className="me-1">
                                  {option?.quantity ? option?.quantity : 1} x
                                </span>
                                {option?.baseLanguage?.optionName}
                                <span className="ml-1  text-13x text-gray-400 ">
                                  {`(${UTILS.toFixedNumber(
                                    option?.price
                                  )} AED)`}
                                </span>
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </span>
              <span className="mx-2">
                <div className="inline-flex center relative flex-flex-4">
                  <span className="inline-flex self-center en-font">
                    <span className="text-20x mx-2">{item?.quantity}</span>
                  </span>
                </div>
              </span>
            </li>
          );
        }))}
      </ul>
    </div>
  );
}

export default OrderedBasketItemList;
