import React from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { Button } from "antd";
import { useParams } from "react-router-dom";

import UserLogo from "components/UserLogo";
import WithLoading from "components/Hoc/WithLoading";
import { NotFound, ErrorComponent } from "components";
import LoginSignupButton from "components/LoginSignupButton";
import StoreHeaderDetail from "components/StoreHeaderDetail";

import useCurrentUser from "hooks/useCurrentUser";
import useQueryParam from "modules/dine-in/useQueryParam";

import C from "Constants";
import useEventTracker from "hooks/useEventTracker";
import { usePlatform } from "hooks";
import { useCustomHistory } from "hooks";

const DienInPayLater = () => {
  usePlatform();
  const params = useParams();
  const router = useCustomHistory();
  const queryParam = useQueryParam();
  const { DomainType } = useSelector((state) => state.AppReducer);
  const { notFound, brandMenuError } = useSelector(
    (state) => state?.brand
  );
  const { selectedStore } = useSelector((state) => state.store);
  const { brandInfo } = useSelector(
    (state) => state?.brandStorageReducer
  );
  const { currentUser } = useCurrentUser();
  const { isLoading } = useSelector((state) => ({
    isLoading: state?.loader.isLoading,
  }));
  const { ordered } = useSelector((state) => state.order);
  const { ordersTotalPrice } = ordered;

  useEventTracker({
    name: "HOME_DINE_IN",
    orderType: "DINE_IN",
    shouldLog: true,
  });


  const goToSettings = () => {
    if (params) {
      router(
        `/dine-in/auth/settings`,
        `/${params?.domainName}/dine-in/auth/settings`,
        DomainType,
        null,
        `?${queryParam}`
      );
    }
  };

  const goToMenu = () => {
    if (params) {
      router(
        `/dine-in`,
        `/${params?.domainName}/dine-in`,
        DomainType,
        null,
        `?${queryParam}`
      );
    }
  };

  const viewBasketPage = () => {
    if (params) {
      router(
        `/dine-in/checkout`,
        `/${params?.domainName}/dine-in/checkout`,
        DomainType,
        null,
        `?${queryParam}`
      );
    }
  };

  const goToLogin = () => {
    router(
      "/dine-in/auth/login",
      `/${params?.domainName}/dine-in/auth/login`,
      DomainType,
      null,
      `?${queryParam}`
    );
  };

  if (!isLoading && notFound) return <NotFound message={notFound} />;
  return (
    <WithLoading>
      <div className="store-page store-page-popup">
        <div className="location-bar bg-location py-2 px-4">
          <div className="flex justify-between items-center">
            <Button
              onClick={() => {}}
              className={`border border-brand-base-primary rounded-lg text-white bg-brand-base-primary py-1 px-2`}
            >
              {t("Dine In")}
            </Button>
            <div>
              {currentUser?.token ? (
                <UserLogo {...{ goToSettings }} />
              ) : (
                <LoginSignupButton {...{ goToLogin }} />
              )}
            </div>
          </div>
        </div>
        <div
          className="h-288x flex flex-col justify-center items-center bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${brandInfo?.setting?.brandImageHomeBG})`,
          }}
        >
          <div
            className="w-200x bg-cover bg-center bg-no-repeat flex-shrink-0 rounded-lg shadow-md shadow-lg border-4 border-black"
            style={{
              height: "150px",
              width: "200px",
              backgroundImage: `url(${brandInfo?.setting?.brandLogoFirst})`,
              border: "12px white solid ",
            }}
          ></div>
        </div>
        <StoreHeaderDetail
          page={C.BRAND}
          brandInfo={brandInfo}
          description={C.SERVICE_TYPE.DINE_IN}
          headerTitle={selectedStore?.baseLanguage?.name}
          activeServices={[]}
          orderType={null}
        />

        <div>{brandMenuError && <ErrorComponent error={brandMenuError} />}</div>

        <div className="p-4 pb-2">
          <button
            type="button"
            onClick={viewBasketPage}
            style={{ marginTop: "auto" }}
            className="text-brand-text-primary bg-brand-base-primary hover:bg-brand-base-primary hover:text-brand-text-primary w-full py-3 rounded px-6 text-brand-text-primary text-16x font-semibold"
          >
            <span className="flex items-center justify-center text-center">
              <div className="flex relative w-full items-center pointer">
                <span className="my-0 mx-auto">
                  {t(`Pay Now (${ordersTotalPrice?.toFixed(2)})! `)}
                </span>
              </div>
            </span>
          </button>
        </div>
        <div className="p-4 pt-2 mb-auto">
          <button
            type="button"
            onClick={goToMenu}
            style={{ marginTop: "auto" }}
            className="text-brand-text-primary bg-brand-base-primary hover:bg-brand-base-primary hover:text-brand-text-primary w-full py-3 rounded px-6 text-brand-text-primary text-16x font-semibold"
          >
            <span className="flex items-center justify-center text-center">
              <div className="flex relative w-full items-center pointer">
                <span className="my-0 mx-auto">{t("View Menu")}</span>
              </div>
            </span>
          </button>
        </div>
      </div>
    </WithLoading>
  );
};

export default DienInPayLater;
