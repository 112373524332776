import React, { useEffect } from "react";
import { matchPath  } from "react-router";
import i18n from "i18n/i18n";
import { t } from "i18next";
import { UTILS } from "utils";
import Routes from "routes";
import { useWindowDimensions } from "hooks";
import UpdateSetting from "./util";
import { useDispatch, useSelector } from "react-redux";
import { BrandAction } from "store/actions";
import { Spinner } from "components";
import { useState } from "react";
import { NotFound } from "components";
import C from "Constants";
import usePayLaterOrders from "hooks/usePayLaterOrders";

const App = () => {
  const dispatch = useDispatch();
  const [Error, setError] = useState(false);
  const { brandInfo } = useSelector((state) => state?.brandStorageReducer);
  const { loading, error } = useSelector((state) => state?.brand);
  const { getBrandSettinsgByID, getBrandSettinsgByName } = UpdateSetting();
  const { height } = useWindowDimensions();
  const [isUserCard , setIsUserCard ] = useState(false);
  document.documentElement.style.setProperty("--app-height", height + "px");

  const { error:paylaterError } = usePayLaterOrders(brandInfo);

  if (paylaterError) {
    console.error("Error fetching pay later orders:", paylaterError);
  }

  useEffect(() => {
    async function fetchBrandSettings() {
      setError(false);
      dispatch(BrandAction.getBrandInfo());
      const headers = await UTILS.getHeaders();
      const location = window?.location?.pathname;
      const URL = matchPath(location, {
        path: [
          "/:slug/card",
          "/:slug/business-card",
          "/:slug/businesscard",
          "/:domainName"],
        });

        if (URL?.params?.slug) {
          setIsUserCard(true);
        } else if (headers?.brandid) {
          getBrandSettinsgByID({ brandId: headers?.brandid });
        } else if (URL?.params?.domainName) {
          getBrandSettinsgByName({
            temporaryDomainName: URL.params.domainName,
          });
        } else {
          setError(true);
          dispatch(
            BrandAction.getbrandInfoFailure(i18n.t(C.DEFAULT_ERROR_MSG))
          );
        }
    }

    fetchBrandSettings();
    return () => {};
  }, []);

  if (loading || (brandInfo === null && error === null && !isUserCard ))  return <Spinner />;
  if (Error)
    return (
      <NotFound
        message={t(C.DEFAULT_ERROR_MSG)}
        sentryError={C.Root_ID_Issue}
      />
    );
  return <Routes />;
};

export default App;
