import React from "react";
import { t } from "i18next";
import CustomModal from "components/CustomModal";
import useUpdateUserProfile from "service-hooks/useUpdateUserProfile";
import TextInput from "components/TextInput";

const AddUserInfoForm = (props) => {
  const { isOpenUserInfoModal, setUserInfoModal, closeOnBgClick } = props;
  const { formstate, onFinish, updateFormState } = useUpdateUserProfile({
    setUserInfoModal,
    isOpenUserInfoModal,
  });

  const { values, errors } = formstate;
  const { name, email, loading:isLoading, error } = values;

  const stopClickPropagation = (e) => e.stopPropagation();

  const renderButton = () => {

    // const isLoading = formstate?.values?.loading;
    const buttonText = isLoading ? t("Loading") : t("Submit");
    return (
      <button
        type="submit"
        onClick={isLoading ? stopClickPropagation : onFinish}
        disabled={isLoading}
        className="cursor-pointer block text-center py-3 rounded text-brand-text-primary bg-brand-base-primary text-16x w-full"
      >
        <span className="flex items-center justify-center text-center">
          <div className="flex justify-center relative w-full items-center pointer">
            <span className="font-bold text-center">{buttonText}</span>
          </div>
        </span>
      </button>
    );
  };
  

  return (
    <CustomModal
      open={isOpenUserInfoModal}
      setOpen={setUserInfoModal}
      bodyclasses="flex "
      overlayClasses="fixed h-screen overflow-y-auto w-screen top-auto bottom-0 bg-black bg-opacity-50 overflolw-hidden transition ease-linear duration-700 z-40 border-sc"
    >
      <div
        onClick={closeOnBgClick}
        className="w-full height100vh flex items-end"
      >
        <div
          className="bg-white w-full max-460 "
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full  p-6 bg-white rounded-lg ">
            <h1 className="my-0 mx-auto  text-18x xs:text-22x font-bold text-outer-space leading-28x w-full text-center mb-4 ">
              {t("Update your profile")}
            </h1>

            {error && (
              <div className="text-red-500 text-center mb-4">
                {error}
              </div>
            )}

            <TextInput
              label={t("Your Name")}
              value={name}
              onChange={(e) => updateFormState("name", e.target.value)}
              name="mapAddress"
              mandatory={true}
              labelClasses="text-14x text-black"
              inputClasses="focus:outline-none mb-4 flex-1 text-16x block w-full rounded border shadow-input px-2 py-3 border-gray-200"
              placeHolder={t("Your Name")}
              readOnly={false}
              error={errors.name}
            />

            <TextInput
              label={t("Email Address")}
              value={email}
              onChange={(e) => updateFormState("email", e.target.value)}
              name="mapAddress"
              mandatory={false}
              labelClasses="text-14x text-black"
              inputClasses="focus:outline-none mb-4 flex-1 text-16x block w-full rounded border shadow-input px-2 py-3 border-gray-200 "
              placeHolder={t("Email Address")}
              readOnly={false}
              error={errors?.email}
            />
            {renderButton()}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default AddUserInfoForm;
