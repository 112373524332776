import { useDispatch } from "react-redux";
import { StoreAction } from "store/actions";
import { PaymentMethodAction } from "store/actions";
import { PaymentCardsAction } from "store/actions";
import C from "Constants";
import { useEffect } from "react";


const PayLaterTab = ({ optionSelected , paymentOptions }) => {
  const dispatch = useDispatch();


  useEffect(() => {
    if(optionSelected && optionSelected === 2){
        resetPaymentType();
        handleSelectPayLater();
    }
  } , [optionSelected])

  const resetPaymentType = () => {
    dispatch(
      PaymentCardsAction.setstripeSelectedCard({
        cardInfo: {},
        isStoredCard: false,
      })
    );
    dispatch(PaymentCardsAction.setstripePaymentCards([]));
    dispatch(StoreAction.setSelectedPaymentMethod(null));
    dispatch(PaymentMethodAction.setSelectedPaymentType(null));
  };

  const handleSelectPayLater = () => {
    if(paymentOptions){
        const payLater = paymentOptions?.find(
            (dIPO) => dIPO.paymentType === C.PAY_LATER
          );
          dispatch(StoreAction.setSelectedPaymentMethod(payLater));
          dispatch(PaymentMethodAction.setSelectedPaymentType(C.PAY_LATER));
          dispatch(PaymentCardsAction.resetSelectedCards());
    }
  };

  return (
    <div
      className="w-full flex flex-col text-center align-center justify-center"
    >
      <span className="text-22x font-bold">Happy Eating!</span>
      <img
        src={`${process.env.PUBLIC_URL}/images/fork-spoon-plate.svg`}
        alt="Fork and Spoon Icon"
        className="w-96x h-96x flex self-center"
      />
      <span className="text-17x">Scan QR code again later to pay your bill anytime.</span>
    </div>
  );
};

export default PayLaterTab;
