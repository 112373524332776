import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTip: null,
};

const TipMethods = createSlice({
  name: "tip_methods",
  initialState,
  reducers: {
    setSelectedTip: (state, action) => {
      state.selectedTip = action.payload;
    },
  },
});

export const { setSelectedTip } = TipMethods.actions;

export default TipMethods.reducer;
