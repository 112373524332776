import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { t } from "i18next";
//components
import CheckoutPaymentMethod from "components/checkout/CheckoutPaymentMethod";
import PlaceOrderButton from "components/checkout/PlaceOrderButton";
import BasketItemList from "components/basket/BasketItemList";
import DiscountButton from "components/basket/DiscountButton";
import WithLoading from "components/Hoc/WithLoading";
import DineInTableNo from "../components/DineInTableNo";
import WithTransparentSpinner from "components/Hoc/WithTransparentSpinner";
import {
  PageHeader,
  ItemsPaymentTotalDetail,
  FixedFooter,
  ErrorComponent,
} from "components";
import EmptyBasket from "components/basket/EmptyBasket";
import CheckoutModals from "../components/Modals";
//actions
import { DiscountAction, CartAction, NotPersistAction } from "store/actions";
//custom hooks
import { useCustomHistory, useWindowLocation } from "hooks";
import C from "Constants";
//services

//custom hooks
import LoyaltyCreditWidget from "components/brand-loyalty/LoyaltyCreditWidget";
import LoyaltyRedeemSwitch from "components/brand-loyalty/LoyaltyRedeemSwitch";
import { LoyaltyAction } from "store/actions";
import { calculateCartGrossAmount } from "utils/Utils";
import { min } from "lodash";
import { useUserWallet } from "hooks";
import TextareaInput from "components/TextareaInput";
import usePaymentHelper from "features/payment-helpers/usePaymentHelper";
import useBrandLosgisticIntegrations from "hooks/useBrandLosgisticIntegrations";
import useQueryParam from "modules/dine-in/useQueryParam";
import SavedCardsList from "components/checkout/SavedCardsList";
import CardWidgets from "components/checkout/CardWidgets";
import WalletPay from "helpers/payment_helpers/stripe/WalletPay";
import { UTILS } from "utils";
import useSuccesOrder from "../../useSuccesOrder";
import usePlaceOrderHelper from "features/payment-helpers/usePlaceOrderHelper";
import { ViewApplicableDiscountedItemsList } from "components/DiscounteditemsList";
import DiscounteditemsList from "components/DiscounteditemsList";
import useShowUserInfoModal from "features/basket/useShowUserInfoModal";
import AddUserInfoForm from "components/Modals/AddUserInfoForm";
import DineInTableOptions from "../components/DineInTableOptions";
import Tips from "../components/Tips";
import PayLaterTab from "../components/PayLaterTab";
import OrderedBasketItemList from "components/basket/OrderedBasketItemList";

//
function Checkout() {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const router = useCustomHistory();
  const queryParam = useQueryParam();
  const { isOpenUserInfoModal, setUserInfoModal } = useShowUserInfoModal();

  const handleSuceessOrderRoute = useSuccesOrder();
  const { outlet_based_place_order } = usePlaceOrderHelper(
    handleSuceessOrderRoute
  );
  const windowRouter = useWindowLocation();
  const queryParams = queryString.parse(location?.search);
  const reduxState = useSelector((state) => state);
  const { orderSuccess } = reduxState.NotPersist;
  const { DomainType } = reduxState.AppReducer;
  const { error: discountError } = reduxState.Discounts;
  const { discountModal } = reduxState.NotPersist;
  const { discountInfo } = reduxState.Discounts;
  const { integrationsByName } = useBrandLosgisticIntegrations();
  const { selectedStore, selectedPayment } = reduxState.store;
  const { selectedPaymentType } = useSelector(
    (state) => state.PaymentMethodReducer
  );
  const {ordered} = useSelector(
    (state) => state.order
  );

  const { cart, cartTotal, amountAfterDiscount, orderSplReq } = reduxState.cart;

  //varables
  const { loyaltyPolicy } = reduxState.brandStorageReducer;
  const { isDiscountApplied } = reduxState.Discounts;
  const { loyaltyApplied } = useSelector((state) => state?.Loyalty);
  const userWallet = useUserWallet();
  const [redeemAmount, setRedeemAmount] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmationCallback, setConfirmationCallback] = useState(
    () => () => {}
  );
  const [confirmModalTitle, setConfirmModalTitle] = useState({
    title: "",
    message: "",
  });

  const redirectLink = {
    success: `dine-in/checkout?${queryParam}&payment=success`,
    failure: `dine-in/checkout?${queryParam}&payment=failure`,
  };

  const {
    placeOrder,
    captureCheckoutDotComPayment,
    cardProcessing,
    isLoading,
  } = usePaymentHelper(redirectLink, handleSuceessOrderRoute);

  const [ selectedPaymentOptions, setSelectedPaymentOptions] = useState(null);

  const updateCart = () => {
    const { itemsGrossAmount, itemsNetPrice, orderGrossAmount } =
      calculateCartGrossAmount(cart, discountInfo, userWallet , ordered);
    dispatch(
      CartAction.updateCartPrice({
        originalPrice: itemsNetPrice,
        amountAfterDiscount: itemsGrossAmount,
        itemsGrossAmount,
        orderGrossAmount,
      })
    );
  };

  useEffect(() => {
    updateCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, discountInfo, redeemAmount]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "POP") {
        dispatch(NotPersistAction.toggleOrderPlaceModel(false));
        if (cart?.length <= 0) {
          sessionStorage.removeItem("paymentVerified");
          windowRouter(
            `/dine-in/?${queryParam}`,
            `/${params.domainName}/dine-in/?${queryParam}`,
            DomainType
          );
        }
      }
    });

    const returnToHome = () => {
      if ( ordered.allOrders?.length <= 0 && cart?.length <= 0 && !orderSuccess) {
        sessionStorage.removeItem("paymentVerified");
        windowRouter(
          `/dine-in/?${queryParam}`,
          `/${params.domainName}/dine-in/?${queryParam}`,
          DomainType
        );
      }
    };
    returnToHome();
    return () => {
      unlisten();
    };
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSuccess, history]);

  const increaseItemQuantity = (id, index) => {
    const payload = { id, index };
    dispatch(CartAction.increaseItemQuantity(payload));
    updateCart();
  };
  const decreaseItemQuantity = (id, index) => {
    const payload = { id, index };
    dispatch(CartAction.decreaseItemQuantity(payload));
    updateCart();
  };

  //
  const openModal = () => {
    dispatch(NotPersistAction.toggleDiscountModal(true));
  };

  const goBack = () => {
    router(
      "/dine-in",
      `/${params.domainName}/dine-in/`,
      DomainType,
      null,
      `?${queryParam}`
    );
  };

  const calculateRedeemAmount = () => {
    const maxRedeemableValuePerOrder =
      loyaltyPolicy?.maxRedeemableValuePerOrder || 0;
    const redeemablePercentagePerOrder =
      loyaltyPolicy?.redeemablePercentagePerOrder || 0;
    const redeemableAmount = Number(
      (cartTotal / 100) * redeemablePercentagePerOrder
    );
    let minimum = min([
      maxRedeemableValuePerOrder,
      redeemableAmount,
      userWallet,
    ])?.toFixed(2);
    setRedeemAmount(Number(minimum));
    dispatch(LoyaltyAction.updateloyaltyAmount(Number(minimum)));
  };

  useEffect(() => {
    calculateRedeemAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartTotal, loyaltyPolicy, cart, userWallet]);

  const switchLoyaltyStatus = () => {
    if (loyaltyApplied) {
      dispatch(LoyaltyAction.removeLoyalty());
      updateCart();
    } else {
      dispatch(LoyaltyAction.applyLoyalty(Number(redeemAmount)));
      updateCart();
    }
  };
  const handleConfirmDiscountLoyalty = (wantsToApply) => {
    if (loyaltyApplied && wantsToApply === C.DISCOUNT) {
      setConfirmModal(true);
      setConfirmModalTitle({
        title: t("Use discount code"),
        message: t(
          "To use discount code, the loyalty points will be removed. Do you want to continue"
        ),
      });

      setConfirmationCallback(() => () => {
        dispatch(LoyaltyAction.removeLoyalty());
        openModal();
        updateCart();
      });
    } else if (
      (isDiscountApplied || discountInfo) &&
      wantsToApply === C.LOYALTY
    ) {
      setConfirmModal(true);
      setConfirmModalTitle({
        title: t("Use loyalty credits"),
        message: t(
          "To use loyalty points, the discount code will be removed. Do you want to continue"
        ),
      });
      setConfirmationCallback(() => () => {
        dispatch(DiscountAction.setCoponValidation(false));
        dispatch(DiscountAction.removeDiscountCode());
        switchLoyaltyStatus();
        updateCart();
      });
    } else if (wantsToApply === C.LOYALTY) {
      switchLoyaltyStatus();
    } else if (wantsToApply === C.DISCOUNT) {
      openModal();
    }
  };

  const handleConfirmModal = () => {
    setConfirmModal(false);
    confirmationCallback();
  };

  const handleCancelModal = () => {
    setConfirmModal(false);
  };

  const validateOrderShipping = () => {
    const { errors, isValid, obj } = UTILS.validateOutletOrderObject();
    return {
      errors,
      isValid,
      obj,
    };
  };

  const OrderPaymentInfo = () => {
    let rate = selectedStore?.taxRate || 0;
    return (
      <ItemsPaymentTotalDetail
        original={cartTotal}
        deliveryFee={0}
        taxRate={rate}
        amountAfterDiscount={amountAfterDiscount}
        userWallet={userWallet}
      >
        {loyaltyPolicy?.isLoyaltyActive && userWallet > 0 && (
          <LoyaltyRedeemSwitch
            loyaltyPolicy={loyaltyPolicy}
            cartTotal={cartTotal}
            amountAfterDiscount={cartTotal}
            userWallet={userWallet}
            switchLoyaltyStatus={() => handleConfirmDiscountLoyalty(C.LOYALTY)}
            redeemAmount={redeemAmount}
          />
        )}
      </ItemsPaymentTotalDetail>
    );
  };

  return (
    <WithLoading loading={isLoading}>
      { ordered?.allOrders?.length <= 0 && cart?.length <= 0 && !orderSuccess ? (
        <EmptyBasket backToHome={goBack} />
      ) : (
        <div className="checkout-page min-h-app-height store-page-popup">
          <PageHeader
            pageHeaderClasses="flex justify-between  border-black-100 px-4 w-full border-b-2 relative"
            url={true}
            handleClick={goBack}
            title={t("Your Order")}
          />
          {queryParams?.[C.DINE_SERVICE_TYPE.TABLE_NUMBER] ? (
            <div className="py-5 border-black-100  w-full border-b-2">
              <DineInTableNo
                buttonText={"Add discount code "}
                tabelNo={queryParams?.[C.DINE_SERVICE_TYPE.TABLE_NUMBER]}
              />
            </div>
          ) : null}
          {cart.length > 0 && <BasketItemList {...{ increaseItemQuantity, decreaseItemQuantity }} />}
          { ordered?.allOrders?.length > 0 && <OrderedBasketItemList  />}
          <div className="px-4 pb-6 mt-6">
            <label className="text-16x font-bold mb-4">
              {t("Special instructions")}
            </label>
            <TextareaInput
              onChange={(e) =>
                dispatch(CartAction.setOrderSplreq(e.target.value))
              }
              value={orderSplReq}
              placeholder={t("Add a note or any instructions.")}
              styleClasses="mt-1 block w-full text-16x text-outer-space mt-2 p-1 bg-gray-100  border border-gray-300 rounded focus:outline-none"
            />
          </div>

          <div className="pt-5">
            <DiscountButton
              buttonText={t("Get discount code")}
              handleDiscount={() => handleConfirmDiscountLoyalty(C.DISCOUNT)}
            />
          </div>

          {!discountModal && discountError && cart?.length >= 1 && (
            <ErrorComponent error={discountError} />
          )}
          <ViewApplicableDiscountedItemsList />
          <DiscounteditemsList couponCode={null} />
          {loyaltyPolicy?.isLoyaltyActive && (
            <LoyaltyCreditWidget {...{ loyaltyPolicy }} />
          )}
          {cart && cart.length > 0 && <Tips />}
          <OrderPaymentInfo />
          <DineInTableOptions
            setSelectedPaymentOptions={setSelectedPaymentOptions}
          >
            <CheckoutPaymentMethod
              paymentOptions={selectedStore?.dineInPaymentOptions}
            >
              <SavedCardsList
                {...{
                  integrationsByName,
                  captureCheckoutDotComPayment,
                }}
              />
              {selectedPayment?.paymentType === C.CREDIT_CARD &&
              selectedPaymentType === C.WALLET.NEW_CARD ? (
                <CardWidgets
                  {...{
                    integrationsByName,
                    captureCheckoutDotComPayment,
                    redirectLink: redirectLink,
                  }}
                />
              ) : null}
            </CheckoutPaymentMethod>
            <PayLaterTab optionSelected={selectedPaymentOptions} paymentOptions={selectedStore?.dineInPaymentOptions} />
          </DineInTableOptions>
          <CheckoutModals
            confirmModal={confirmModal}
            setConfirmModal={setConfirmModal}
            handleConfirmModal={handleConfirmModal}
            handleCancelModal={handleCancelModal}
            confirmModalTitle={confirmModalTitle}
          />
            {integrationsByName[C.STRIPE] ? (
              <WalletPay
                selectedStore={selectedStore}
                placeOrder={outlet_based_place_order}
                payUsingWallet={validateOrderShipping}
              >
                <FixedFooter>
                  <PlaceOrderButton
                    placeOrder={placeOrder}
                    loading={cardProcessing}
                    minOrder={0}
                  />
                </FixedFooter>
              </WalletPay>
            ) : (
              <FixedFooter>
                <PlaceOrderButton
                  placeOrder={placeOrder}
                  loading={cardProcessing}
                  minOrder={0}
                />
              </FixedFooter>
            )}

          <WithTransparentSpinner loading={cardProcessing || isLoading} />
        </div>
      )}
      <AddUserInfoForm
        {...{ isOpenUserInfoModal, setUserInfoModal }}
        closeOnBgClick={() => {}}
      />
    </WithLoading>
  );
}

export default Checkout;
