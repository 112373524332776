import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
  cartTotal: 0, //this is original amount of items in cart
  amountAfterDiscount: 0, //this is amount after discount applied on itemsor loyalty points
  discountAmount: 0,
  itemsGrossAmount: 0, //this is amount after discount applied on items
  orderGrossAmount: 0, //this is amount after discount applied on items + delivery fee
  deliveryFee: 0,
  orderSplReq: "",
  pickupVehicleInfo: "",
  pullAddress: true,
  deliveryChargesWaiverInfo: {
    isWaiver: false,
    waiverAmount: 0,
  },
  tipAmount:0,
  taxAmount:0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      state.cart = [...state.cart, action.payload];
    },
    setPickupVehicleInfo: (state, action) => {
      state.pickupVehicleInfo = action.payload;
    },

    setDiscountAmount: (state, action) => {
      state.discountAmount = action.payload;
    },
    removeItemFromCart: (state, action) => {
      state.cart = state.cart.filter((item) => item.id !== action.payload);
    },
    increaseItemQuantity: (state, action) => {
      const item = state.cart[action.payload.index];
      item.quantity += 1;
    },
    decreaseItemQuantity: (state, action) => {
      const item = state.cart[action.payload.index];
      if (item.quantity > 1) {
        item.quantity -= 1;
      } else {
        state.cart.splice(action.payload.index, 1);
      }
    },
    clearCart: (state) => {
      state.cart = [];
      state.cartTotal = 0;
      state.amountAfterDiscount = 0;
      state.discountAmount = 0;
      state.tipAmount = 0;
      state.taxAmount = 0;
    },
    updateCartPrice: (state, action) => {
      state.cartTotal = action.payload.originalPrice;
      state.amountAfterDiscount = action.payload.amountAfterDiscount;
      state.itemsGrossAmount = action.payload.itemsGrossAmount;
      state.orderGrossAmount = action.payload.orderGrossAmount;
      if(action.payload.discountAmount){
        state.discountAmount = action.payload.discountAmount;
      }
      if(action.payload.tipAmount){
        state.tipAmount = action.payload.tipAmount;
      }
      if(action.payload.taxAmount){
        state.taxAmount = action.payload.taxAmount;
      }
    },

    setDeliveryFee: (state, action) => {
      state.deliveryFee = action.payload;
    },
    setdeliveryChargesWaiverInfo: (state, action) => {
      state.deliveryChargesWaiverInfo = action.payload;
    },
    setOrderSplreq: (state, action) => {
      state.orderSplReq = action.payload;
    },
    togglePullAddress: (state, action) => {
      state.pullAddress = action.payload;
    },
  },
});

export const {
  setdeliveryChargesWaiverInfo,
  togglePullAddress,
  setPickupVehicleInfo,
  setOrderSplreq,
  setDeliveryFee,
  addItemToCart,
  removeItemFromCart,
  increaseItemQuantity,
  decreaseItemQuantity,
  clearCart,
  updateCartPrice,
  setDiscountAmount,
} = cartSlice.actions;
export default cartSlice.reducer;
