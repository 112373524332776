import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,
  myorders: [],
  placedOrder: null,
  orderInfo: null,
  orderStatus: true,
  transactionInfo: null,
  orderRef: null,
  ordered: {
    'ordersTotalPrice':0,
    'allOrders':[]
  }
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    placeOrder: (state) => {
      state.placedOrder = null;
      state.error = null;
    },
    setOrderRef: (state, action) => {
      state.orderRef = action.payload;
    },
    placeOrderSuccess: (state, action) => {
      state.placedOrder = action.payload;
      state.error = null;
    },
    placeOrderFailure: (state, action) => {
      state.error = action.payload;
    },

    setOrdered: (state , action) => {
      state.ordered = action.payload;
    },
    resetOrdered : (state , action) => {
      state.ordered = {
        'ordersTotalPrice':0,
        'ordersTotalTax':0,
        'allOrders':[],
      };
      state.error = null;
    },

    getMyOrders: (state, action) => {
      state.myorders = [];
      state.error = null;
    },
    getMyOrdersSuccess: (state, action) => {
      state.myorders = action.payload;
      state.error = null;
    },
    getMyOrdersFailure: (state, action) => {
      state.error = action.payload;
      state.myorders = [];
    },
    getOrderStatus: (state, action) => {
      state.orderStatus = true;
      state.orderInfo = null;
      state.error = null;
    },
    getOrderStatusSuccess: (state, action) => {
      state.orderInfo = action.payload;
      state.orderStatus = true;
      state.error = null;
    },
    getOrderStatusFailure: (state, action) => {
      state.error = action.payload;
      state.orderStatus = false;
    },
    getPaymentPage: (state) => {
      state.error = null;
    },
    getPaymentPageSuccess: (state, action) => {
      state.error = null;
      state.error = null;
      state.transactionInfo = action.payload;
    },
    getPaymentPageFailure: (state, action) => {
      state.error = action.payload;
    },
    verifyPayment: (state) => {
      state.error = null;
    },
    verifyPaymentSuccess: (state, action) => {
      state.error = null;
      state.transctionInfo = action.payload;
    },
    verifyPaymentFailure: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setOrderRef,
  placeOrder,
  placeOrderSuccess,
  placeOrderFailure,
  getMyOrders,
  setOrdered,
  resetOrdered ,
  getMyOrdersSuccess,
  getMyOrdersFailure,
  getOrderStatus,
  getOrderStatusFailure,
  getOrderStatusSuccess,
  getPaymentPage,
  getPaymentPageFailure,
  getPaymentPageSuccess,
  verifyPayment,
  verifyPaymentFailure,
  verifyPaymentSuccess,
} = orderSlice.actions;
export default orderSlice.reducer;
